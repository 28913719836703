import BasesService from 'services/basesManager.service';
import { Observer } from 'shared/services/observer-manager/observer.service';

export function useObserveBases(callback?: () => Promise<unknown> | unknown): void {
  const basesManager = inject<BasesService>(BasesService.getServiceName());
  const baseObserver = ref<Observer | undefined>();
  const basisId = ref<number>(basesManager?.currentUserBase?.id ?? 0);

  onMounted(() => {
    baseObserver.value = basesManager?.subscribe(async () => {
      const currentBasisId = basesManager?.currentUserBase?.id;
      if (basisId.value != currentBasisId) {
        basisId.value = currentBasisId ?? 0;
        await callback?.();
      }
    });
  });

  onUnmounted(() => baseObserver.value && basesManager?.unsubscribe(baseObserver.value));
}
